<template>
  <div class="rightLink">
    <div class="bottomDiv" style="height: 700px;">
      <div class="title">计费模板添加</div>
      <div class="formDiv">
        <el-form
        
          ref="FormRules"
          :rules="rules"
          :model="FormData"
          label-position="right"
          label-width="100px"
        >
        <el-row :gutter="155">
          <el-col :span="11">
              <el-form-item label="充电类型:">
                <el-select
                  style="width: 100%"
                  v-model="FormData.type_id"
                  placeholder="请选择充电类型"
                  @change="handleChange"
                >
                  <el-option :value="2" label="电动车" />
                  <el-option :value="4" label="电动汽车" />
                </el-select>
              </el-form-item>
            </el-col>
        </el-row>

        <!-- 电动车 -->
        <div style="margin-top:50px" v-if="FormData.type_id == 2">
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="模板名称:">
                <el-input
                  v-model="FormData.price_name"
                  placeholder="请输入模板名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" v-if="isadmin">
              <el-form-item label="运营商:">
                <el-select v-model="FormData.username" placeholder="请选择运营商" style="width: 100%;">
                  <el-option v-for="username in users" :key="username.id" :value="username.username" :label="username.username"/>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="11" v-if="!isadmin">
              <el-form-item label="运营商:">
                <el-input
                  v-model="FormData.username"
                  placeholder="请输入所属运营商"
                ></el-input>
              </el-form-item>
            </el-col> -->
            <!-- <el-col :span="11">
              <el-form-item label="计费类型1:">
                <el-select
                  style="width: 100%"
                  v-model="FormData.type"
                  placeholder="请选择模板类型"
                  @change="handleChange2"
                >
                  <el-option value="0" label="小时" />
                  <el-option value="1" label="度" />
                  <el-option value="2" label="功率" />
                </el-select>
              </el-form-item>
            </el-col> -->
            <el-col :span="20">
              <el-form-item label="计费类型:">
                <el-radio-group v-model="radio" @change="radio1">
                  <el-radio :label="0" name="0">按时长收费</el-radio>
                  <el-radio :label="1" name="1">按电费+服务费收费</el-radio>
                  <el-radio :label="2" name="2">按功率时长收费</el-radio>
                </el-radio-group>
              </el-form-item>
              
            </el-col>
          </el-row>
            <el-form-item label="功率金额:" v-show="isgl">
              <el-row :gutter="20" class="inputs1">
                <el-col :span="3">
                 <p class="up1">0</p>
                
                </el-col>
                -
                <el-col :span="3">
                  <el-input
                    v-model="FormData.time_frame0"
                  
                ></el-input>
                </el-col>
                W
                <el-col :span="0.5"></el-col>
                <el-col :span="3">
                  <el-input
                    v-model="FormData.time_frame03"
                  
                ></el-input>
                </el-col>
                元/小时
                
              </el-row>
              <el-row :gutter="20" class="inputs1">
                <el-col :span="3">
                  <p class="up1">{{ FormData.time_frame0 }}</p>
                
                </el-col>
                -
                <el-col :span="3">
                  <el-input
                    v-model="FormData.time_frame1"
                  
                ></el-input>
                </el-col>
                W
                <el-col :span="0.5"></el-col>
                <el-col :span="3">
                  <el-input
                    v-model="FormData.time_frame013"
                  
                ></el-input>
                </el-col>
                元/小时
                
              </el-row><el-row :gutter="20" class="inputs1">
                <el-col :span="3">
                  <p class="up1">{{ FormData.time_frame1 }}</p>
                </el-col>
                -
                <el-col :span="3">
                  <el-input
                    v-model="FormData.time_frame2"
                  
                ></el-input>
                </el-col>
                W
                <el-col :span="0.5"></el-col>
                <el-col :span="3">
                  <el-input
                    v-model="FormData.time_frame023"
                  
                ></el-input>
                </el-col>
                元/小时
                
              </el-row><el-row :gutter="20" class="inputs1">
                <el-col :span="3">
                  <p class="up1">{{ FormData.time_frame2 }}</p>
                </el-col>
                -
                <el-col :span="3">
                  <el-input
                    v-model="FormData.time_frame3"
                  
                ></el-input>
                </el-col>
                W
                <el-col :span="0.5"></el-col>
                <el-col :span="3">
                  <el-input
                    v-model="FormData.time_frame33"
                  
                ></el-input>
                </el-col>
                元/小时
                
              </el-row><el-row :gutter="20" class="inputs1">
                <el-col :span="3">
                  <p class="up1">{{ FormData.time_frame3 }}</p>
                </el-col>
                -
                <el-col :span="3">
                  <el-input
                    v-model="FormData.time_frame4"
                  
                ></el-input>
                </el-col>
                W
                <el-col :span="0.5"></el-col>
                <el-col :span="3">
                  <el-input
                    v-model="FormData.time_frame43"
                  
                ></el-input>
                </el-col>
                元/小时
                
              </el-row><el-row :gutter="20" class="inputs1">
                <el-col :span="3">
                  <p class="up1">{{ FormData.time_frame4 }}</p>
                </el-col>
                -
                <el-col :span="3">
                  <el-input
                    v-model="FormData.time_frame5"
                  
                ></el-input>
                </el-col>
                W
                <el-col :span="0.5"></el-col>
                <el-col :span="3">
                  <el-input
                    v-model="FormData.time_frame53"
                  
                ></el-input>
                </el-col>
                元/小时
                
              </el-row><el-row :gutter="20" class="inputs1">
                <el-col :span="3">
                  <p class="up1">{{ FormData.time_frame5 }}</p>
                </el-col>
                -
                <el-col :span="3">
                  <el-input
                    v-model="FormData.time_frame6"
                  
                ></el-input>
                </el-col>
                W
                <el-col :span="0.5"></el-col>
                <el-col :span="3">
                  <el-input
                    v-model="FormData.time_frame63"
                  
                ></el-input>
                </el-col>
                元/小时
                
              </el-row>
             
          </el-form-item>
          
          <el-row :gutter="20">
            <el-col :span="11" v-show="!isgl">
              <el-form-item label="单价(元):">
                <el-input
                  v-model="FormData.price"
                  placeholder="请输入单价"
                ></el-input>
              </el-form-item>
              
            </el-col>
            
          </el-row>

          <div class="btnDiv">
            <el-button @click="submit()">提交</el-button>
          </div>
        </div>
        <!-- 电动汽车 -->
        <div style="margin-top:50px" v-if="FormData.type_id == 4">
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="模板名称:">
                <el-input
                  v-model="FormData.price_name"
                  placeholder="请输入模板名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" v-if="isadmin">
              <el-form-item label="运营商:">
                <el-select v-model="FormData.username" placeholder="请选择运营商" style="width: 100%;">
                  <el-option v-for="username in users" :key="username.id" :value="username.username" :label="username.username"/>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="11" v-if="!isadmin">
              <el-form-item label="运营商:">
                <el-input
                  v-model="FormData.username"
                  placeholder="请输入所属运营商"
                ></el-input>
              </el-form-item>
            </el-col> -->
          </el-row>
          
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="谷峰电费:">
                <el-input
                  v-model="FormData.d_electric"
                  placeholder="请输入谷峰电费"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="谷峰服务费:">
                <el-input
                  v-model="FormData.d_service"
                  placeholder="请输入谷峰服务费"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="平峰电费:">
                <el-input
                  v-model="FormData.p_electric"
                  placeholder="请输入平峰电费"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="平峰服务费:">
                <el-input
                  v-model="FormData.p_service"
                  placeholder="请输入平峰服务费"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="高峰电费:">
                <el-input
                  v-model="FormData.g_electric"
                  placeholder="请输入尖峰服务费"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="高峰服务费:">
                <el-input
                  v-model="FormData.g_service"
                  placeholder="请输入尖峰服务费"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="11">
              <el-form-item label="尖峰电费:">
                <el-input
                  v-model="FormData.j_electric"
                  placeholder="请输入尖峰电费"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="尖峰服务费:">
                <el-input
                  v-model="FormData.j_service"
                  placeholder="请输入尖峰服务费"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="formtip" style="color:#606266; padding-top: 3px; box-sizing: border-box;font-size:15px;margin-left:27px;">
                <span>尖峰：10:30-11:30、19:00-21:00；</span>
                <span>高峰：8:30-10:30、18:00-19:00、21:00-23:00；</span>
                <span>平峰：12:00-17:00；</span>
                <span>谷峰：23:00-07:00。</span>  
              </div>
          <div class="btnDiv" style="width: 100%;">
            <el-button style="margin: 0 auto;" @click="submit">提交</el-button>
          </div>
        </div>
         
        </el-form>
        
      </div>
    </div>
  </div>
</template>
  <script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { add_template,billing_models,operator_data } from "@/request/api";
import { ElMessage } from "element-plus";
export default {
  name: "TempAdd",
  
  setup() {
    const data = reactive({
      radio: 0,
      //表单数据
 
      FormData:{
        username:localStorage.getItem('username'),
        type_id:4,
        type:0
      },
      istwo:false,
      isfour:false,
      isgl:false,
     
      power1:'',
      checketimes:[],
      isadmin:false,
      users:[],
    });
    // const start1 = computed(() => data.FormData.time_frame0?data.FormData.time_frame0.value + 1:'')
    const submit = () => {
      if(Object.keys(data.FormData).length < 4){
          ElMessage({
            message: '请完整输入！',
            type: 'warning',
          })
        } else {
          add_template(data.FormData).then((res) => {
            if (res) {
              if (res.code == 200) {
                ElMessage.success("添加成功");
                data.FormData = {
                  price_name: "",
                  price: "", 
                  type: "",
                };
              } else {
                ElMessage.error(res.msg);
              }
            } else {
              ElMessage.error("提交失败");
            }
          });
        }
    };
    const radio1 = (a)=>{
      console.log(a,'0000000type');
      data.FormData.type = a
      if(a == 2){
        data.isgl = true
        
      }else{
        data.isgl = false
      }
    }
    const handleChange = (value) => {
      console.log(value,'触发了几轮');
      if(value == 2){
        data.istwo = true,
        data.isfour = false
      }else if(value == 4){
        data.istwo = false,
        data.isfour = true
      }
      
    };
    const handleChange2 = (value) => {
      console.log(value,'触发了什么');
      if(value == 2){
        data.isgl = true
        
      }else{
        data.isgl = false
      }
      
    };


    const submit1 = () => {
      // const dataa = data.FormData
      // console.log(dataa,'四轮计费模板');
      if(Object.keys(data.FormData).length < 10){
          ElMessage({
            message: '请完整输入！',
            type: 'warning',
          })
        } else {
          
          billing_models(data.FormData).then((res) => {
        
        if (res) {
          if (res.code == 200) {
            ElMessage.success("添加成功");
            data.FormData = {}
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("提交失败");
        }
      });
    };
        }
      
    const getUsername = () => {
        const username1 = localStorage.getItem('username')
        const users1 = []
        operator_data().then((res) => {
          data.users = res
          res.forEach((a)=>{    
            users1.push(a.username)
        })
        if(users1.indexOf(username1)==-1){
          data.isadmin = true
          data.FormData.username=""
        }else{
          data.isadmin = false
          data.FormData.username = username1
          
        }
        });
      };

    onMounted(() => {
      console.log(data);
      getUsername()
    });
    return {
      ...toRefs(data),
      submit,
      submit1,
      handleChange,
      handleChange2,
      getUsername,
      radio1
      // opentime,
      // checkTime,
      // checkTime1,
      // checkTime2,
      // checkTime3,
      // submitDialog,
      // submitDialog1,
      // submitDialog2,
      // submitDialog3,
    };
  },
  
};
</script>
<style>
  .btnDiv {
    margin-top: 50px;
  }
  .inputs1{
    margin-bottom: 4px;
  }
  .up1{
    width: 80px;
    border-radius: 5px;
    height: 40px;
    border: 1px solid lightseagreen;
    background-color: rgb(247, 255, 255);
    line-height: 40px;
    text-align: center;
  }
</style>
